<template>
	<div>
		<vs-table
			search
			stripe
			border
			description
			:sst="true"
			:data="table.data"
			:max-items="table.length"
			:total="table.total"
            @search="handleSearch"
		>
			<template slot="thead">
				<vs-th style="max-width: 50px;">Row Number</vs-th>
				<vs-th>Bulletin Code</vs-th>
				<vs-th>Bulletin Ex Code</vs-th>
				<vs-th>Ex Code</vs-th>
				<vs-th>Row Data <a id="downloadAnchorElem" style="display:none"></a></vs-th>
				<vs-th sort-key="status">Status</vs-th>
				<vs-th sort-key="remark">Remark</vs-th>
			</template>
			<template slot-scope="{ data }">
				<vs-tr :data="tr" :key="indextr" v-for="(tr, indextr) in data">
					<vs-td>{{ (indextr + 1) }}</vs-td>
					<vs-td>{{ tr.Data.BulletinCode }}</vs-td>
					<vs-td>{{ tr.Data.BulletinExCode }}</vs-td>
					<vs-td>{{ tr.Data.ExCode }}</vs-td>
                    <vs-td :data="tr"><a href="#" v-on:click="downloadJson(tr, indextr)">Json File</a></vs-td>
					<vs-td>{{ tr.Status }}</vs-td>
					<vs-td style="max-width: 200px;">{{ tr.Remark }}</vs-td>
				</vs-tr>
			</template>
		</vs-table>
	</div>
</template>

<script>
import moment from 'moment'
export default {
	props: ["selected"],
	components: {
		// 
	},
	mounted() {
		// 
		console.log("selected", this.selected)
		this.setDataDetail();
	},
	data() {
		return {
			table: {
				all_data: [],
				data: [],
				length: 10,
				page: 1,
				search: "",
				order: "id",
				sort: "asc",
				total: 0,
				totalPage: 0,
				totalSearch: 0,
				limits: [10, 25, 50, 100, "All"],
				start: 1,
				end: 0,
			},
		};
	},
	methods: {
		dateFormatGMT7(date) {
            if (date) {
				// ini karena datanya berformat `date time`, bukan `datetimezone`
				// jadi GMT(+7) ditambah +7 =>utc offset = 14
                return moment(String(date)).format('DD/MM/YYYY HH:mm')
            }
        },
		setDataDetail() {
			console.log("this.selected", this.selected)
			this.table.total = this.selected.LogImportLineDatas.length;
			this.table.totalPage = 1;
			this.table.totalSearch = this.selected.LogImportLineDatas.length;
			this.table.length = this.selected.LogImportLineDatas.length;
			this.table.data = this.selected.LogImportLineDatas;
			this.table.all_data = this.selected.LogImportLineDatas;
		},
		downloadJson(tr, indexTr){
			console.log("tr", tr);
			var dataStr = "data:text/json;charset=utf-8," + encodeURIComponent(JSON.stringify(tr.Data));
			var fileName = (indexTr+1)+"_"+tr.Data.BulletinCode + ".json"
			var dlAnchorElem = document.getElementById('downloadAnchorElem');
			dlAnchorElem.setAttribute("href",     dataStr     );
			dlAnchorElem.setAttribute("download", fileName);
			dlAnchorElem.click();
		},
		setStartEnd() {
			let valStart =
				this.table.length * this.table.page - this.table.length + 1;
			if (valStart > this.table.total) {
				valStart = 1;
			}
			if (this.table.total == 0) {
				valStart = 0;
			}
			let valEnd = this.table.length * this.table.page;
			if (valEnd > this.table.total) {
				valEnd = this.table.total;
			}
			if (this.table.totalSearch < this.table.total) {
				valEnd = this.table.totalSearch;
			}
	
			this.table.start = valStart;
			this.table.end = valEnd;
		},
		handleSearch(search) {
			console.log("this.table.data", this.table.data)
            if (search == "") {
				this.table.data = this.table.all_data;
			} else {
				var dataFilter =  this.table.all_data.filter(v => {
					console.log(v);
					let condFilter = (v.Status.includes(search) || v.Data.BulletinCode.includes(search) || v.Data.BulletinExCode.includes(search) || v.Data.ExCode.includes(search) || v.Data.ExCode.includes(search) || v.Remark.includes(search))
					console.log("condFilter", condFilter);
					if (condFilter) {
					  return v
					}
				});
				this.table.data = dataFilter;
			}
        },
	},
  	watch: {
        watchedProperties: function() {
			console.log("selected", this.selected)
			this.setDataDetail();
        }
    },
	computed: {
		watchedProperties() {
            return this.selected;
        },
		setPage: {
			get() {
				return 1;
			},
			set(val) {
				this.handleChangePage(val);
			},
		},
	},
};
</script>

 <style scoped>
.nonfixed {
  position: inherit;
  padding-left: 20px;
}
.core-enter-active {
  transition: all 0.3s ease;
}
.core-leave-active {
  transition: all 0.8s cubic-bezier(1, 0.5, 0.8, 1);
}
.core-enter, .core-leave-to
/* .slide-fade-leave-active below version 2.1.8 */ {
  transform: translateX(10px);
  opacity: 0;
}
.core {
  -webkit-transform-origin-y: all 1s ease;
  -webkit-transition: all 1s ease;
  -moz-transition: all 1s ease;
  -o-transition: all 1s ease;
  transition: all 1s ease;
}
.vs-con-table .vs-con-tbody .vs-table--tbody-table {
  font-size: 12px;
}
.vertical-divider {
  border-right: 1px solid #7367f0;
  /* min-height: 800px; */
  /* height: 100%; */
  -webkit-mask-position-y: fixed;
  /* padding: 5px; */
}
.vs-con-table.stripe .tr-values:nth-child(2n) {
  background: beige;
}
.colored {
  border: 1px solid #7367f0;
  position: fixed;
  left: 4%;
  top: 40%;
  max-width: 45%;
  z-index: 999999999999;
  background: antiquewhite;
  background-color: antiquewhite;
}
</style>